import { Redirect } from 'react-router-dom';
import { notification } from 'antd';
import { apiGetUserProfile, apiSignOut } from '../api/user.api';
import { initialState, setUserItem } from './user.store';

// typed wrapper async thunk function demo, no extra feature, just for powerful typings

export const logoutAsync = () => {
  return async dispatch => {
    dispatch(setUserItem(initialState));
    localStorage.clear();
  };
};

export const getUserProfile = () => {
  return async dispatch => {
    try {
      const { data } = await apiGetUserProfile();
      dispatch(setUserItem(data));
    } catch (error) {
      console.log('Error aman', error);
      notification.error({
        message: 'Error',
        description: error?.response?.data?.detail || 'Something went wrong',
      });
      setTimeout(() => {
        window.location.href = '/login';
      }, 2000);
    }
  };
};

export const loginUser = () => {
  return async dispatch => {
    dispatch(setUserItem({ logged: true }));
  };
};

export const logoutUser = () => {
  return async dispatch => {
    const { data } = await apiSignOut();

    dispatch(setUserItem(initialState));
  };
};
