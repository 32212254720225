import { notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import request from '../request';

function Invite(props) {
  const [redirectTo, setRedirectTo] = useState(null);
  const code = new URLSearchParams(props.location.search).get('token');
  const isNew = new URLSearchParams(props.location.search).get('isnew');

  const acceptInvitation = async code => {
    try {
      let res = await request.post(
        '/tenant/accept-invitation',
        {},
        {
          params: { code },
        }
      );

      if (res.status !== 201) {
        notification.error({
          message: res?.data?.detail || 'Failed to accept invitation',
          description: res?.data?.detail,
        });
        return;
      }

      notification.success({
        message: 'Invitation Accepted',
        description: res?.data?.detail,
      });
    } catch (error) {
      notification.error({
        message: error?.response?.data?.detail || 'Failed to accept invitation',
        description: 'Please try again later.',
      });
    }
  };

  const validateInvitation = async code => {
    try {
      if (!code) {
        return;
      }
      console.log('validate code', code);
      let res = await request.post(
        '/tenant/validate-invite-code',
        {},
        {
          params: { code },
        }
      );

      if (res.status !== 200) {
        notification.error({
          message: res?.data?.detail || 'Invalid Invitation',
          description: res?.data?.detail,
        });
        return;
      }

      if (res.data?.isNew) {
        localStorage.setItem('invitation_token', code);
        console.log(localStorage.getItem('invitation_token'));
        setRedirectTo('/create-account');
      } else {
        localStorage.setItem('invitation_token', code);
        console.log(localStorage.getItem('invitation_token'));
        setRedirectTo('/login');
      }

      notification.success({
        message: 'Invitation Valid',
        description: res?.data?.detail,
      });
    } catch (error) {
      notification.error({
        message: error?.response?.data?.detail || 'Invalid Invitation',
        description: 'Please try again later.',
      });
    }
  };

  useEffect(() => {
    // if (code && (isNew === null || isNew === undefined)) {
    //   console.log('isnew is null');
    //   acceptInvitation(code);
    // }
    // if (isNew === 'true' && code) {
    //   localStorage.setItem('invitation_token', code);
    //   console.log(localStorage.getItem('invitation_token'));
    //   setRedirectTo('/create-account');
    // } else {
    //   localStorage.setItem('invitation_token', code);
    //   console.log(localStorage.getItem('invitation_token'));
    //   setRedirectTo('/login');
    // }
    if (code) {
      validateInvitation(code);
    }
  }, [code, isNew]);

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  // return <Redirect to="/app/dashboard" />;
}

export default withRouter(Invite);
