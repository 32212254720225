import request from '../request';

export const apiGetUserProfile = async () => {
  try {
    return await request.get('/auth/me');
  } catch (error) {
    console.log('Error aman', error);
    throw error;
  }
};

//
//
export const apiSignOut = async () => {
  return await request.post('/auth/signout');
};

export const acceptInvite = async code => {
  let res = await request.post(
    '/tenant/accept-invitation',
    {},
    {
      params: { code },
    }
  );

  return res;
};
